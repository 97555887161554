a,
.nav-list-expander,
.icon {
  color: #1565c0 !important;
}

.site-footer {
  color: #5c5962 !important;
}

.table-wrapper {
  box-shadow: none !important;
}

table.display td {
  background-color: #f5f6fa;
}

.metatags {
  background-color: #f5f6fa;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #eeebee;

  p.label {
    margin: 2px;
    font-weight: bold;
    text-transform: none;
    background-color: #1565c0 !important;

    a {
      color: #fff !important;
    }
  }
}

.sel-tableitem-wrap {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

#sel-tableitem {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.875rem;
  background-color: #fff;
  color: #5c5962;
  border: 0;
}

#wcb {
  background-color: #fff;
  padding: 6px;
}

#wcb_a {
  color: #fff !important;
}

@media (min-width: 50rem) and (min-width: 31.25rem) {
  .nav-list .nav-list-item {
    font-size: 0.9rem !important;
  }
}
